import React from "react";

import "../assets/scss/components/_addon-section.scss";
import { useProductStore } from "../stores/ProductStore";

import { Image } from "./Image";
import Config from "../Config";

import Sebastian from "../assets/images/addons/sebastian.jpg";
import PralinesRed from "../assets/images/addons/pralines-red.jpg";
import PralinesBlack from "../assets/images/addons/pralines-black.jpg";
import LuxuryBelgium from "../assets/images/addons/luxury-belgium.jpg";
import RedWine from "../assets/images/addons/red-wine.jpg";
import WhiteWine from "../assets/images/addons/white-wine.jpg";
import Pigalle from "../assets/images/addons/pigalle.jpg";
import LuxuryVase from "../assets/images/addons/luxury-vase.jpg";
import Cake from "../assets/images/addons/cake.jpg";
import BowlVase from "../assets/images/addons/bowl-vase.jpg";
import GinTonic from "../assets/images/addons/gin-tonic.jpg";
import LuxuryJellies from "../assets/images/addons/luxury-jellies.jpg";
import Candle from "../assets/images/addons/candle.jpg";
import EcoVase from "../assets/images/addons/eco-vase.jpg";
import Moet from "../assets/images/addons/moet.jpg";
import MoetSmall from "../assets/images/addons/moet-small.jpg";
import ClearVase from "../assets/images/addons/clear-vase.jpg";
import LouisDornier from "../assets/images/addons/louis-dornier.jpg";
import Guylian from "../assets/images/addons/guylian.jpg";
import GinAndTonicPostal from "../assets/images/addons/gin-postal.jpg";
import AfternoonTeaPostal from "../assets/images/addons/afternoon-tea-postal.jpg";
import WinePostal from "../assets/images/addons/wine-postal.jpg";
import HappyBirthdayPostal from "../assets/images/addons/happy-birthday-postal.jpg";
import LuxuryChocolatePostal from "../assets/images/addons/luxury-chocolate-postal.jpg";
import PamperPostal from "../assets/images/addons/pamper-postal.jpg";
import GardeningPostal from "../assets/images/addons/gardening-postal.jpg";
import ChocolatePostal from "../assets/images/addons/chocolate-postal.jpg";
import NewBabyPostal from "../assets/images/addons/new-baby-postal.jpg";
import Secateurs from "../assets/images/addons/secateurs.jpg";
import Slider from "./Slider";

export const AddonSection = ({ addedToCart }) => {
	const { liveUrl } = Config;
	const { availableAddons, SetAddons, selectedAddons, includedAddons } = useProductStore(({ availableAddons, SetAddons, selectedAddons, includedAddons }) => ({ availableAddons, SetAddons, selectedAddons, includedAddons }));

	const temporaryImages = {
		1: ClearVase,
		2: Sebastian,
		5: WhiteWine,
		6: RedWine,
		7: MoetSmall,
		8: Cake,
		12: LouisDornier,
		28: Pigalle,
		42: BowlVase,
		1059: LuxuryVase,
		56: GinTonic,
		93: LuxuryJellies,
		11: Candle,
		86: PralinesBlack,
		87: PralinesRed,
		1024: GinAndTonicPostal,
		1025: PamperPostal,
		1027: EcoVase,
		1029: EcoVase,
		1031: AfternoonTeaPostal,
		1032: WinePostal,
		1033: Sebastian,
		1048: Secateurs,
		1049: Guylian,
		1056: LuxuryBelgium,
		1071: Moet,
		1078: Candle,
		1081: HappyBirthdayPostal,
		1082: GardeningPostal,
	};

	const addonsPerSlide = window.innerWidth > 768 ? 12 : 4;

	const addonSlides = Object.keys(availableAddons).reduce(
		(acc, addonId) => {
			if (includedAddons[addonId] !== undefined) return acc;
			if (temporaryImages[addonId] === undefined) return acc;

			// Check if a new slide should be started
			if (acc[acc.length - 1].length === addonsPerSlide) {
				acc.push([]);
			}

			acc[acc.length - 1].push(addonId);

			return acc;
		},
		[[]]
	); // Initialize with one empty array to handle the first slide

	return availableAddons && includedAddons && Object.keys(availableAddons).length > 0 ? (
		<section className="addon-section">
			<strong className="section-title">Let's make your gift even more special:</strong>

			{includedAddons && Object.keys(includedAddons).length > 0 && (
				<div
					className="included-addons-wrapper row justify-content-space-between"
					style={{
						"--gap": "10px",
					}}
				>
					<strong className="col-12">Included With This Product:</strong>
					{Object.keys(includedAddons).map((addonId) => {
						let addon = includedAddons[addonId];
						addonId = parseInt(addonId);

						if (temporaryImages[addonId] === undefined) return;

						return (
							<div
								className="addon included-addon col-12 col-md-4"
								key={addonId}
							>
								<div className="row align-items-center justify-content-space-between">
									<div className="addon-image col-3">
										<Image
											src={temporaryImages[addonId] ?? `${liveUrl}/images/attributes/${includedAddons[addonId].image_name}`}
											alt={addon.pretty_name}
										/>
									</div>
									<div className="addon-info row align-items-start justify-content-space-between col-8">
										<span className="addon-name col-fluid">{addon.pretty_name}</span>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}

			<Slider
				slides={addonSlides.map((slide, index) => {
					return {
						element: (
							<div
								className="addons-wrapper row justify-content-flex-start"
								style={{
									"--gap": "10px",
								}}
							>
								{slide.map((addonId) => {
									let addon = availableAddons[addonId];
									addonId = parseInt(addonId);

									// if (temporaryImages[addonId] === undefined) return;
									// if (includedAddons[addonId] !== undefined) return;

									return (
										<div
											className="addon col-6 col-md-3"
											key={addonId}
											onClick={() => {
												SetAddons(addonId);
											}}
										>
											<div className="addon-image">
												<Image
													src={temporaryImages[addonId] ?? `${liveUrl}/images/attributes/${availableAddons[addonId].image_name}`}
													alt={addon.pretty_name}
												/>

												<div className={`addon-check-status ${selectedAddons.includes(addonId) ? "checked" : ""}`}>{selectedAddons.includes(addonId) ? "Added To Cart" : "Add To Cart"}</div>
											</div>
											<div className="addon-info row align-items-start justify-content-space-between">
												<span className="addon-name col-fluid">{addon.pretty_name}</span>
												<p className="addon-price">{parseFloat(addon.price) === 0 ? <strong>FREE!</strong> : `£${parseFloat(addon.price).toFixed(2)}`}</p>
											</div>
										</div>
									);
								})}
							</div>
						),
					};
				})}
				slides_per_row={1}
				prefix="addons-slider"
				autoplay={false}
				arrows={true}
			/>

			{/* <div
				className="addons-wrapper row justify-content-start"
				style={{
					"--gap": "10px"
				}}
			>
				{Object.keys(availableAddons).map((addonId) => {
					let addon = availableAddons[addonId];
					addonId = parseInt(addonId);

					if (temporaryImages[addonId] === undefined) return;
					if (includedAddons[addonId] !== undefined) return;

					return (
						<div
							className="addon col-6 col-md-3"
							key={addonId}
							onClick={() => {
								SetAddons(addonId);
							}}
						>
							<div className="addon-image">
								<Image
									src={temporaryImages[addonId] ?? `${liveUrl}/images/attributes/${availableAddons[addonId].image_name}`}
									alt={addon.pretty_name}
									// imported={true}
								/>

								<div className={`addon-check-status ${selectedAddons.includes(addonId) ? "checked" : ""}`}>{selectedAddons.includes(addonId) ? "Added To Cart" : "Add To Cart"}</div>
							</div>
							<div className="addon-info row align-items-start justify-content-space-between">
								<span className="addon-name col-fluid">{addon.pretty_name}</span>
								<p className="addon-price">{parseFloat(addon.price) === 0 ? <strong>FREE!</strong> : `£${parseFloat(addon.price).toFixed(2)}`}</p>
							</div>
						</div>
					);
				})}
			</div> */}
		</section>
	) : (
		<></>
	);
};
