import React from "react";
import TagManager from "react-server-side-gtm";

import { useProductStore } from "../stores/ProductStore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/pro-regular-svg-icons";

const GA4AddToCart = (productInfo) => {
	TagManager.dataLayer({
		dataLayer: {
			event: "add_to_cart",
			currency: "GBP",
			ecommerce: {
				add: {
					products: {
						name: productInfo.products_name,
						id: productInfo.products_id,
						price: productInfo.price_retail,
						category: productInfo.product_type,
						delivery_availability: productInfo.delivery.lead_time,
						product_group: "base"
					}
				}
			}
		}
	});
};

export const AddToCartButton = ({ info, setSecondStage, inStock, secondStage }) => {
	const { originalPrice, msrpPrice, SetCurrentProductStep } = useProductStore((state) => state);

	return (
		<button
			className={`btn add-to-cart-button ${inStock ? "in-stock" : "disabled"} emphasis-button`}
			onClick={() => {
				SetCurrentProductStep("next");
				setSecondStage(true);

				try {
					GA4AddToCart(info);
				} catch (error) {
					console.error("Error setting GA4 data", error);
				}
			}}
			disabled={!inStock && true}
		>
			<div className="button-text">
				{inStock ? (
					<>
						<FontAwesomeIcon icon={faShoppingBasket} /> Add To Cart
					</>
				) : (
					<>Out Of Stock</>
				)}
			</div>
			{inStock && (
				<div className="pricing">
					{parseFloat(info.price_msrp) > parseFloat(info.price_retail) && <div className="msrp">£{parseFloat(msrpPrice).toFixed(2)}</div>}
					<div className="retail">£{parseFloat(originalPrice).toFixed(2)}</div>
				</div>
			)}
		</button>
	);
};
